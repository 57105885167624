import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useParams, useLocation } from 'react-router-dom';
import { dataSet } from '@actions/dataset';
import { useDispatch } from 'react-redux';
import _, { set } from 'lodash';
import LoadingOverlay from '@dvcode/react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader'
import { useHistory } from 'react-router-dom';
import config from '@config';
import PointAnnotationTool from '@components/Annotation/PointAnnotationTool';
import Swal from 'sweetalert2'

function LabeledDataView(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const [isDataLoad, setIsDataLoad] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [polygonAnnotations, setPolygonAnnotations] = useState([]);

  const [pointAnnotations, setPointAnnotations] = useState([]);

  const [scale, setScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });

  const annotationToolRef = useRef(null);

  useEffect(() => {
    setIsDataLoad(true)
    dispatch(dataSet(params.labeled_data_id))
    .then((res) => {
      setCurrentItem(res);

      if(!res.tci_boundary || res.tci.length < 9){
        Swal.fire({
          title: '경고',
          text: '데이터가 올바르게 가공되지 않았습니다. 데이터 가공 페이지로 이동합니다.',
          icon: 'warning',
          confirmButtonText: '확인'
        }).then(() => {
          history.replace(`/sc1/data-labeling/${params.labeled_data_id}`)
        });
        return;
      }

      if(res.tci_boundary){
        setPolygonAnnotations([res.tci_boundary])
      }

      if(res.tci){
        setPointAnnotations([...res.tci])
      }

      setIsDataLoad(false)
    })
    .catch(message => {
      history.replace('/sc1/labeled-data')
    });
  }, []);

  const resetZoom = () => {
    const stage = annotationToolRef.current.getStageRef();
    stage.scale({ x: 1, y: 1 });
    stage.position({ x: 0, y: 0 });
    stage.batchDraw();
    setScale(1);
    setStagePosition({ x: 0, y: 0 });
  };

  return (
    <>
      <LoadingOverlay
        active={isDataLoad}
        spinner={<ClockLoader size={40} speedMultiplier={2} cssOverride={{marginBottom: '15px'}} color='#fff'/>}
        text='데이터를 불러오는 중입니다.'
      />
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={<Link className="header-link" to={`/sc1/labeled-data${location.search}`}><i className='ni ni-bold-left'/>{props.pageNameText}</Link>}
      />
      {currentItem && <>
      <Container className="pt-4" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                <colgroup>
                    <col width='10%'></col>
                    <col width='20%'></col>
                    <col width='10%'></col>
                    <col width='20%'></col>
                    <col width='10%'></col>
                    <col width='20%'></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                        <th className="text-center">데이터 구성</th>
                        <td className="text-left">{currentItem.data_category_name}</td>
                        <th className="text-center">환자번호</th>
                        <td className="text-left">{currentItem.patient.patient_id}</td>
                        <th className="text-center">진단 이름</th>
                        <td className="text-left">{currentItem.name}</td>
                    </tr>
                    <tr>
                        <th className="text-center">TCI 결과</th>
                        <td className="text-left">{currentItem.datalabel_label_category}</td>
                        <th className="text-center">TCI MIN</th>
                        <td className="text-left">{currentItem.tci_min}</td>
                        <th className="text-center">TCI MAX</th>
                        <td className="text-left">{currentItem.tci_max}</td>
                    </tr>
                  </tbody>
                </Table>
                <PointAnnotationTool
                  viewOnly={true}
                  ref={annotationToolRef}
                  imageUrl={`${config.API_SERVER_HOST}/patients/${currentItem?.patient.patient_id}/datasets/${currentItem.id}/image`}
                  polygonAnnotation={polygonAnnotations[0]}
                  annotations={pointAnnotations}
                  scale={scale}
                  stagePosition={stagePosition}
                  onResetZoom={resetZoom}
                  onStagePositionChange={setStagePosition}
                  onScaleChange={setScale}
                />
              </CardBody>
            </Card>

            <Card key='result'>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='20%'></col>
                    <col width=''></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      <th className="text-center">진단자 최종결과</th>
                      <td className="text-left">
                          {currentItem.result_label_category}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-center">진단자 의견</th>
                      <td className="text-left">
                        {currentItem.memo}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container></>}
    </>
  );
}

export default LabeledDataView;