import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Alert,
  Input,
  Button
} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useParams, useLocation } from 'react-router-dom';
import { dataSet, updateDiagnosis } from '@actions/dataset';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import _, { set } from 'lodash';
import LoadingOverlay from '@dvcode/react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader'
import { useHistory } from 'react-router-dom';
import config from '@config';
import PointAnnotationTool from '@components/Annotation/PointAnnotationTool';
import Swal from 'sweetalert2'

function LabeledDataView(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const inputRef = useRef();
  const selectRef = useRef();
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [polygonAnnotations, setPolygonAnnotations] = useState([]);

  const [pointAnnotations, setPointAnnotations] = useState([]);

  const [scale, setScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });

  const annotationToolRef = useRef(null);

  useEffect(() => {
    setIsDataLoad(true)
    dispatch(dataSet(params.ai_analysis_id))
    .then((res) => {
      setCurrentItem(res);

      if(res.tci_boundary && res.tci && res.tci.length === 9){
        setPointAnnotations([...res.tci])
        setPolygonAnnotations([res.tci_boundary])
      }

      setIsDataLoad(false)
    })
    .catch(message => {
      history.replace('/sc0/ai-analysis')
    });
  }, []);

  const resetZoom = () => {
    const stage = annotationToolRef.current.getStageRef();
    stage.scale({ x: 1, y: 1 });
    stage.position({ x: 0, y: 0 });
    stage.batchDraw();
    setScale(1);
    setStagePosition({ x: 0, y: 0 });
  };

  const onCreateLabel = (key) => {
    const data_category = key;
    const createLabelData = {
      dataset_id: params.ai_analysis_id,
      data_category
    }

    setCreateResultLoading(true)
    dispatch(createResult(createLabelData))
    .then((res) => {
      if(!_.some(currentItem.datalabel, {id: res.id})) {
        currentItem.airesult.push(res)
        setCurrentItem({...currentItem})        
      }
      setCreateResultLoading(false)
      toast.success(`${dataCategory.find(r=>r.code === data_category)?.name} 분석 완료`, {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch(message => {
      console.log(message)
      // history.replace('/sc1/data-labeling')
    });
  }


  const onUpdateDiagnosis = (e) => {
    dispatch(
      updateDiagnosis(params.ai_analysis_id, {
        result_label_category: selectRef.current.value,
        memo: inputRef.current.value
      })
    ).then((res) => {
      toast.success(`진단자 결과저장 완료`, {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  }

  return (
    <>
      <LoadingOverlay
        active={isDataLoad}
        spinner={<ClockLoader size={40} speedMultiplier={2} cssOverride={{marginBottom: '15px'}} color='#fff'/>}
        text='데이터를 불러오는 중입니다.'
      />
      {/* <LoadingOverlay
        active={createResultLoading}
        spinner={<ClockLoader size={40} cssOverride={{marginBottom: '15px'}} color='#fff'/>}
        text='분석중입니다..'
      /> */}
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={<Link className="header-link" to={`/sc1/labeled-data${location.search}`}><i className='ni ni-bold-left'/>{props.pageNameText}</Link>}
      />
      {currentItem && <>
      <Container className="pt-4" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='10%'></col>
                    <col width='20%'></col>
                    <col width='10%'></col>
                    <col width='20%'></col>
                    <col width='10%'></col>
                    <col width='20%'></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                        <th className="text-center">데이터 구성</th>
                        <td className="text-left">{currentItem.data_category_name}</td>
                        <th className="text-center">환자번호</th>
                        <td className="text-left">{currentItem.patient.patient_id}</td>
                        <th className="text-center">진단 이름</th>
                        <td className="text-left">{currentItem.name}</td>
                    </tr>
                    {currentItem.tci.length === 9 && currentItem.tci_boundary && <tr>
                        <th className="text-center">TCI</th>
                        <td className="text-left">{currentItem.datalabel_label_category}</td>
                        <th className="text-center">TCI MIN / MAX</th>
                        <td className="text-left">{currentItem.tci_min} / {currentItem.tci_max}</td>
                    </tr>}
                  </tbody>
                </Table>
                <PointAnnotationTool
                  viewOnly={true}
                  ref={annotationToolRef}
                  imageUrl={`${config.API_SERVER_HOST}/patients/${currentItem?.patient.patient_id}/datasets/${currentItem.id}/image`}
                  polygonAnnotation={polygonAnnotations[0]}
                  annotations={pointAnnotations}
                  scale={scale}
                  stagePosition={stagePosition}
                  onResetZoom={resetZoom}
                  onStagePositionChange={setStagePosition}
                  onScaleChange={setScale}
                />
                </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='15%'></col>
                    <col width='35%'></col>
                    <col width='15%'></col>
                    <col width='35%'></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      <th className="text-center">모델 항목</th>
                      <td className="text-left">
                        <Input type="select" style={{width: '150px'}}>
                          <option value=''>선택해 주세요</option>
                        </Input>
                      </td>
                      <td className="text-left" style={{paddingLeft: '0'}}>
                        { false ? <Button color="default" type="button" onClick={()=>onCreateLabel(code)}>
                          분석시작
                        </Button> :  <Alert color="danger">
                          모델이 설정되지 않아 인공지능 분석을 할수 없습니다.
                        </Alert>}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <img src={`${config.API_SERVER_HOST}/output/${currentItem.test_category}-images/${currentItem?.raw_data?.id}_${code}.png`} style={{width: '100%', height: '100%'}}/> */}
                {currentItem?.airesult.length > 0 && <Table className="align-items-center table-flush mt-3" responsive striped>
                  <colgroup>
                    <col width='150px'></col>
                    <col width='230px'></col>
                    <col width=''></col>
                    <col></col>
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center" scope="col">
                        분석일
                      </th>
                      <th className="text-center" scope="col">
                        TCI 결과
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {/* {currentItem.airesult.filter(r=>r.data_category == code).map((item, i)=><tr key={i}>
                      <td className="text-center"><Moment format='YYYY-MM-DD HH:mm:ss'>{item.created}</Moment></td>
                      <td className="text-center">{item.label_category ? item.label_category : <Spinner
                          color="primary"
                          size="sm"
                        />}</td>
                    </tr>)}
                    {currentItem.airesult.filter(r=>r.data_category == code).length === 0 ? <tr>
                      <td className='text-center' colSpan={2}>분석결과가 없습니다.</td>
                    </tr> : null} */}
                  </tbody>
                </Table>}
              </CardBody>
            </Card>
            
            <Card key='result'>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='20%'></col>
                    <col width=''></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      <th className="text-center">진단자 최종결과</th>
                      <td className="text-left">
                          <Input type="select" innerRef={selectRef} defaultValue={currentItem.result_label_category} style={{width: '150px'}}>
                            {
                              Array.from({length: 19}, (v, i) => i).map((v, i) => {
                                return <option key={i} value={i} selected={currentItem.result_label_category == i}>{i}</option>
                              })
                            }
                          </Input>
                      </td>
                    </tr>
                    <tr>
                      <th className="text-center">진단자 의견</th>
                      <td className="text-left">
                        <Input type="textarea" innerRef={inputRef} defaultValue={currentItem.memo} style={{width: '100%', height:'150px'}}></Input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      
                      </td>
                      <td>
                        <Button color="default" type="button" onClick={onUpdateDiagnosis}>
                            저장하기
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container></>}
    </>
  );
}

export default LabeledDataView;