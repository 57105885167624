import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Input,
  Card,
  CardBody,
  Table,
  Button,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { aimodels } from '@actions/aimodel';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';
import config from '@config';
import _ from 'lodash'
import IO from 'socket.io-client';
import testCategory from '@data/testCategory';
import dataCategory from '@data/dataCategory';

function DataAnalytics(props) {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(useLocation().search);

  const [searchTestCategory, setSearchTestCategory] = useState(searchParams.get('test_category') || '');
  const [searchDataCategory, setSearchDataCategory] = useState(searchParams.get('data_category') || '');
  const [currentItems, setCurrentItems] = useState(null);
  const [activePage, setActivePage] = useState(searchParams.get('page') || 1);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const webSocket = useRef(null);

  useEffect(() => {
    dispatch(aimodels({
      page: currentPage,
      test_category: searchTestCategory,
      data_category: searchDataCategory,
      complete: false,
      limit: 5000
    }))
    .then(res => {
      setActivePage(res.page)
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }, [currentPage, searchTestCategory]);

  useEffect(() => {
    webSocket.current = IO(`${config.WEBSOCKET_HOST}`, {
      // forceNew: false,
      transports: [ 'websocket' ],
      extraHeaders: {},
    })

    webSocket.current.on('connect', () => {
      webSocket.current.emit('join', 'sc2');
    })

    webSocket.current.on('data', res => {
      let data = JSON.parse(res);
      setCurrentItems(_.sortBy(data.items, 'created').reverse())      
    })

    return () => webSocket.current.disconnect();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={props.pageNameText}
      />
      <Container className="pt-5" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
          <Card>
              <CardBody>
                  <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='15%'></col>
                    <col></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      {/* <th className="text-center">진단 장비</th>
                      <td className="text-left">
                        <Input type="select" value={searchTestCategory} onChange={e=>{
                          const url = new URL(window.location);
                          url.searchParams.set('test_category', e.target.value);
                          url.searchParams.set('page', 1);
                          window.history.pushState({}, '', url);
                          
                          setCurrentPage(1)
                          setSearchTestCategory(e.target.value)
                        }}>
                          <option value=''>전체</option>
                          <option value='mp30'>MP30</option>
                          <option value='edf'>수면다원검사</option>
                        </Input>
                      </td> */}
                      <th className="text-center">데이터 항목</th>
                      <td className="text-left">
                       <Input type="select" value={searchDataCategory} onChange={e=>{
                          const url = new URL(window.location);
                          url.searchParams.set('data_category', e.target.value);
                          url.searchParams.set('page', 1);
                          window.history.pushState({}, '', url);
                          
                          setCurrentPage(1)
                          setSearchDataCategory(e.target.value)
                        }}>
                          <option value=''>전체</option>
                          {
                            dataCategory.map(i=><option value={i.code}>{i.name}</option>)
                          }
                        </Input>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              <Row>
                  <Col xs="6" style={{fontSize:'14px', lineHeight: '30px'}}>
                    {/* 학습 진행중 : {totalItemCount}건 */}
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link to="/sc2/ai-learning/create">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-chart-bar-32" />
                        </span>
                        <span className="btn-inner--text">인공지능 학습</span>
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Table className="align-items-center table-flush mt-3" responsive striped>
                  <colgroup>
                    {/* <col width='10px'></col>
                    <col width='auto'></col>
                    <col width='auto'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col> */}
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center" scope="col" rowSpan={2}>
                        순번
                      </th>
                      {/* <th className="text-center" scope="col" rowSpan={2}>
                        진단 장비
                      </th> */}
                      <th className="text-center" scope="col" rowSpan={2}>
                        데이터 항목
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        데이터 수
                      </th>
                      <th className="text-center" scope="col" rowSpan={2}>
                        상태
                      </th>
                      <th className="text-center" scope="col" rowSpan={2}>
                        학습 시작일
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center" scope="col">
                        Normal
                      </th>
                      <th className="text-center" scope="col">
                        Moderate
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                  {
                      currentItems && currentItems.map((item, index)=><tr key={item.id}>
                      <td className="text-center">
                      {totalItemCount - (currentPage - 1) * 10 - index}
                      </td>
                      {/* <td className="text-center">{testCategory[item.test_category].name}</td> */}
                      <td className="text-center">{dataCategory.find((v) => v.code === item.data_category).name || ''}</td>
                      <td className="text-center">{item.input_normal_count}</td>
                      <td className="text-center">{item.input_moderate_count}</td>
                      <td className="text-center">
                        {item.is_running ? <Spinner
                          color="primary"
                          size="sm"
                        /> : item.is_running==undefined ? '' : '정지'}
                      </td>
                      <td className="text-center"><Moment format='YYYY-MM-DD HH:mm:ss'>{item.created}</Moment></td>
                      </tr>)
                    }
                    {currentItems && currentItems.length === 0 && <tr>
                      <td className="text-center" colSpan={7}>데이터가 없습니다.</td>
                    </tr>}
                  </tbody>
                </Table>
                <div className='mt-5'>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemCount}
                    pageRangeDisplayed={5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DataAnalytics;
