import React, {useCallback, useMemo, useEffect, useState} from 'react'

// reactstrap components
import {
  Card,
  CardBody,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useLocation } from 'react-router-dom';
import Moment from 'react-moment';
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from 'react-redux';
import { dataSets } from '@actions/dataset';
import Pagination from 'react-js-pagination';
import { divisions } from 'actions/division';

function AIAnalysis(props) {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(useLocation().search);

  const [divisionData, setDivisionData] = useState([]);
  const [searchDataCategory, setSearchDataCategory] = useState(searchParams.get('data_category') ? searchParams.get('data_category').split(',').map(item=>({label: item, value: item})) : []);
  const [searchDivision, setSearchDivision] = useState(searchParams.get('division') ? searchParams.get('division').split(',').map(item=>({label: item, value: item})) : []);
  const [searchTCI, setSearchTCI] = useState(searchParams.get('tci') ? searchParams.get('tci').split(',').map(item=>({label: item, value: parseInt(item)})) : []);
  const [searchResult, setSearchResult] = useState(searchParams.get('result') ? searchParams.get('result').split(',').map(item=>({label: item, value: parseInt(item)})) : []);

  const [currentItems, setCurrentItems] = useState(null);
  const [activePage, setActivePage] = useState(searchParams.get('page') || 1);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    dispatch(divisions())
    .then(data => {
      setDivisionData(data);
    })

    dispatch(dataSets({
      page: currentPage,
      data_category: searchDataCategory.length === 0 ? '' : searchDataCategory.map(item=>item.value).join(','),
      division: searchDivision.length === 0 ? '' : searchDivision.map(item=>item.value).join(','),
      datalabel_label_category: searchTCI.length === 0 ? '' : searchTCI.map(item=>item.value).join(','),
      result_label_category: searchResult.length === 0 ? '' : searchResult.map(item=>item.value).join(','),
    }))
    .then(res => {
      setActivePage(res.page)
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }, [currentPage, searchDataCategory, searchDivision, searchTCI, searchResult]);

  const handlePageChange = (page) => {
    const url = new URL(window.location);
    url.searchParams.set('page', page);
    window.history.pushState({}, '', url);

    setCurrentPage(page);
  };

  return (
    <>
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={props.pageNameText}
      />
      <Container className="pt-4" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" >
            <Card>
              <CardBody>
              <div style={{ display: 'flex', gap: '10px' }}>
                  <MultiSelect
                    options={[
                      {
                        label: "단일데이터",
                        value: 'single'
                      },
                      {
                        label: "다중데이터",
                        value: 'multiple'
                      }
                    ]}
                    value={searchDataCategory}
                    onChange={data=>{
                      const url = new URL(window.location);
                      url.searchParams.set('data_category', data.map(item=>item.value).join(','));
                      url.searchParams.set('page', 1);
                      window.history.pushState({}, '', url);
                      
                      setCurrentPage(1)
                      setSearchDataCategory(data)                    
                    }}
                    labelledBy="데이터 구성"
                    overrideStrings={{
                      selectSomeItems: "데이터 구성 : 전체",
                      allItemsAreSelected: `데이터 구성 : 전체`,
                      search: "검색",
                      selectAll: "전체",
                    }}
                    valueRenderer={(selected, _options) => {
                      console.log(selected, _options)
                      return selected.length === 0 ? "데이터 구성 : 전체" : `데이터 구성 : ${selected.length == 2 ? '전체' : selected[0].label}`;
                    }}
                    disableSearch={true}
                    isCreatable={true}
                  />
                  <MultiSelect
                    options={divisionData.map(item=>({ label: item, value: item }))}
                    value={searchDivision}
                    onChange={data=>{
                      const url = new URL(window.location);
                      url.searchParams.set('division', data.map(item=>item.value).join(','));
                      url.searchParams.set('page', 1);
                      window.history.pushState({}, '', url);
                      
                      setCurrentPage(1)
                      setSearchDivision(data)                    
                    }}
                    labelledBy="진단 사업소"
                    overrideStrings={{
                      selectSomeItems: "진단 사업소 선택",
                      allItemsAreSelected: `진단 사업소 : 전체`,
                      search: "검색",
                      selectAll: "전체",
                    }}
                    valueRenderer={(selected, _options) => {
                      return selected.length === 0 ? "진단 사업소 : 전체" : `진단 사업소 : ${selected.length === divisionData.length ? '전체' : selected[0].label}`;
                    }}
                    disableSearch={true}
                    isCreatable={true}
                  />
                  <MultiSelect
                    options={Array.from({length: 19}, (_, i) => ({ label: `${i}`, value: i }))}
                    value={searchTCI}
                    onChange={data=>{
                      const url = new URL(window.location);
                      url.searchParams.set('tci', data.map(item=>item.value).join(','));
                      url.searchParams.set('page', 1);
                      window.history.pushState({}, '', url);
                      
                      setCurrentPage(1)
                      setSearchTCI(data)                                        
                    }}
                    labelledBy="TCI"
                    overrideStrings={{
                      selectSomeItems: "TCI 선택",
                      allItemsAreSelected: `TCI : 전체`,
                      search: "검색",
                      selectAll: "전체",
                    }}
                    valueRenderer={(selected, _options) => {
                      return selected.length === 0 ? "TCI : 전체" : `TCI : ${selected.length === 19 ? '전체' : selected.length + '건'}`;
                    }}
                    disableSearch={true}
                    isCreatable={true}
                  />
                  <MultiSelect
                    options={Array.from({length: 19}, (_, i) => ({ label: `${i}`, value: i }))}
                    value={searchResult}
                    onChange={data=>{
                      const url = new URL(window.location);
                      url.searchParams.set('result', data.map(item=>item.value).join(','));
                      url.searchParams.set('page', 1);
                      window.history.pushState({}, '', url);
                      
                      setCurrentPage(1)
                      setSearchResult(data)
                    }}
                    labelledBy="진단자"
                    overrideStrings={{
                      selectSomeItems: "진단자 결과 선택",
                      allItemsAreSelected: `진단자 결과 : 전체`,
                      search: "검색",
                      selectAll: "전체",
                    }}
                    valueRenderer={(selected, _options) => {
                      return selected.length === 0 ? "진단자 결과 : 전체" : `진단자 결과 : ${selected.length === 19 ? '전체' : selected.length + '건'}`;
                    }}
                    disableSearch={true}
                    isCreatable={true}
                  />
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush" responsive striped>
                  <colgroup>
                    <col width='10px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='150px'></col>
                    <col width='100px'></col>
                    <col width='100px'></col>
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center" scope="col">
                        순번
                      </th>
                      <th className="text-center" scope="col">
                        업로드 날짜
                      </th>
                      <th className="text-center" scope="col">
                        데이터 구성<br/>
                        (환자번호)
                      </th>
                      <th className="text-center" scope="col">
                        진단 이름
                      </th>
                      <th className="text-center" scope="col">
                        진단 사업소
                      </th>
                      <th className="text-center" scope="col">
                        TCI 결과
                      </th>
                      <th className="text-center" scope="col">
                        진단자
                      </th>
                      <th className="text-center" scope="col">
                        인공지능 분석
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                  {
                      currentItems && currentItems.map((item, index)=><tr key={item.id}>
                      <td className="text-center">
                        <Link to={`/sc0/ai-analysis/${item.id}${location.search}`}>{totalItemCount - (currentPage - 1) * 10 - index}</Link>
                      </td>
                      <td className="text-center"><Moment format='YYYY-MM-DD HH:mm:ss'>{item.created}</Moment></td>
                      <td className="text-center">
                        {item.data_category_name}<br/>
                        ({item.patient.patient_id})
                      </td>
                      <td className="text-center">{item.name || '-'}</td>
                      <td className="text-center">{item.user?.division}</td>
                      <td className="text-center">{item.datalabel_label_category != null ? item.datalabel_label_category : '-'}</td>
                      <td className="text-center">{item.result_label_category != null ? item.result_label_category : '-'}</td>
                      <td className="text-center">{item.ai_label_category != null ? item.ai_label_category : '-'}</td>
                    </tr>)
                    }

                    {
                      currentItems && currentItems.length === 0 && <tr>
                        <td colSpan={8} className='text-center'>데이터가 없습니다.</td>
                      </tr>
                    }
                  </tbody>
                </Table>
                <div className='mt-5'>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemCount}
                    pageRangeDisplayed={5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AIAnalysis;
