import React, {useCallback, useMemo, useEffect, useState} from 'react'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Progress,
  Input
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '@dvcode/react-loading-overlay';

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { createDataSet, dataSets } from '@actions/dataset';
import {useDropzone} from 'react-dropzone';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { PatientIdGenerator } from 'utils';

import 'react-toastify/dist/ReactToastify.css';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function nameValidator(file) {
  if(!file.name){
    return
  }

  if(file.name.indexOf('.png') > -1 || file.name.indexOf('.jpg') > -1 || file.name.indexOf('.jpeg') > -1)
    return null

  return {
    code: "name-not-allow",
    message: `Name is Not allow`
  };
}

function DataUpload(props) {
  const dispatch = useDispatch();

  const [currentItems, setCurrentItems] = useState(null);
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [uploadFileCount, setUploadFileCount] = useState(0);
  const [uploadFileCompleteCount, setUploadCompleteFileCount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectDataCategory, setSelectDataCategory] = useState('single')
  const [patientId, setPatientId] = useState(PatientIdGenerator.generate());

  useEffect(() => {
    getDataset();
  }, []);

  const getDataset = () => {
    dispatch(dataSets({
      limit: 50,
      page: 1,
    }))
    .then(res => {
      setCurrentItems(res.docs);
      setTotalItemCount(res.totalDocs);
    })
  }

  const onDrop = useCallback(acceptedFiles => {
    if(acceptedFiles.length > 0){
      if(selectDataCategory === ''){
        toast.error('데이터 구성을 선택해주세요.', {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }); 
        
        document.getElementById(`flist-${acceptedFiles[0].name}-memo`).innerHTML = '데이터 구성 미선택으로 업로드 실패';

        return;
      }

      setIsUploadActive(true)
      setUploadFileCount(acceptedFiles.length)
      let upload_count = 0;
      // let newCurrentItems = [...currentItems];

      acceptedFiles.forEach(image=>{
        const formData = new FormData();
        formData.append('image', image);
        formData.append('data_category', selectDataCategory);
        formData.append('patient_id', selectDataCategory === 'single' ? PatientIdGenerator.generate() : patientId);

        dispatch(createDataSet(formData))
        .then(res=>{

          if(res.new){
            // newCurrentItems.unshift(res.savedData)
            document.getElementById(`flist-${image.name}`).style['text-decoration'] = 'line-through red';
            setTotalItemCount(totalItemCount + 1)
          } else {
            document.getElementById(`flist-${image.name}-memo`).innerHTML = '이미 업로드된 파일입니다.';
          }
          
          if(acceptedFiles.length === ++upload_count) {
            setIsUploadActive(false)
            toast.success('데이터 업로드가 완료되었습니다.', {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            setUploadFileCount(0)
            setUploadCompleteFileCount(0)
            // setCurrentItems(newCurrentItems)
            setPatientId(PatientIdGenerator.generate());
            getDataset();
          } else {
            setUploadCompleteFileCount(upload_count)
          }
        })
        .catch(err=>console.log(err))
      })
    } else { 
      toast.error('이미지 파일만 업로드 가능합니다.', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  }, [selectDataCategory, currentItems])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept: { 
      '': ['.png', '.jpg', '.jpeg'],
    },
    useFsAccessApi: false,
    validator: nameValidator,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const files = acceptedFiles.map((file, index) => <li key={file.path}><span id={`flist-${file.path}`}>{file.path}</span><span id={`flist-${file.path}-memo`} style={{marginLeft:'10px', color: '#f5365c'}}></span></li>);

  return (
    <>
      <LoadingOverlay
        active={isUploadActive}
        spinner={<Progress animated value={uploadFileCompleteCount / uploadFileCount * 100} style={{width: '300px',height: '15px'}} />}
        text='업로드 중입니다..'
        />
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={props.pageNameText}
      />
      <Container className="pt-4" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardBody>
                  <div style={{marginBottom: '10px'}}>
                    <label>데이터 구성</label>
                    <Input type="select" style={{ width: '300px'}} onChange={e=>{
                        setSelectDataCategory(e.target.value)
                        setPatientId(PatientIdGenerator.generate());
                      }}>
                      <option value='single' selected={selectDataCategory === 'single'}>단일데이터</option>
                      <option value='multiple' selected={selectDataCategory === 'multiple'}>다중데이터</option>
                    </Input>
                  </div>
                  
                  <div {...getRootProps({style})}>
                  <input {...getInputProps()} />
                  <p>데이터를 여기에 놓거나 클릭 후 선택해 주세요</p>
                  <em>(png / jpeg 파일만 업로드 가능합니다)</em>
                </div>
                <aside className='mt-4' style={{display: files.length ? 'block' : 'none'}}>
                  <h4>업로드 파일</h4>
                  <ul>{files}</ul>
                </aside>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h3 className="mb-2">최근 업로드 이력</h3>
                {currentItems && <Table className="align-items-center table-flush" responsive striped>
                  <colgroup>
                    <col width='150px'></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center" scope="col">
                        순번
                      </th>
                      <th className="text-center" scope="col">
                        업로드 날짜
                      </th>
                      <th className="text-center" scope="col">
                        진단 사업소
                      </th>
                      <th className="text-center" scope="col">
                        데이터 구성<br/>
                        (환자번호)
                      </th>
                      <th className="text-center" scope="col">
                        진단 이름
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {currentItems.map((currentItem, index)=><tr key={currentItem.id}>
                      <td className="text-center">{totalItemCount - index}</td>
                      <td className="text-center"><Moment format='YYYY-MM-DD HH:mm:ss'>{currentItem.created}</Moment></td>
                      <td className="text-center">{currentItem.user.division}</td>
                      <td className="text-center">
                        {currentItem.data_category_name}<br/>
                        ({currentItem.patient.patient_id})
                      </td>
                      <td className="text-center">{currentItem.name}</td>
                    </tr>)}
                    {currentItems.length === 0 && <tr>
                      <td className="text-center" colSpan={5}>업로드된 데이터가 없습니다.</td>
                    </tr>}
                  </tbody>
                </Table>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DataUpload;
