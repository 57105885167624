import React from 'react';
import { Label, Tag, Text } from 'react-konva';

const PointLabel = ({ x, y, text, scale }) => (
  <Label x={x} y={y}>
    <Tag fill="#00BF9D" opacity={0.75} pointerDirection="left" pointerWidth={10/scale} pointerHeight={8/scale} lineJoin="round" />
    <Text text={text} fontSize={14/scale} padding={2/scale} fill="white" />
  </Label>
);

export default PointLabel;