import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  FormGroup,
  Form,
  Button,
  Input,
  Row,
  Col,
  Progress
} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useParams, useLocation } from 'react-router-dom';
import { dataSet, updateDataLabel } from '@actions/dataset';
import { useDispatch, useSelector } from 'react-redux';
import _, { set } from 'lodash';
import { toast } from 'react-toastify';
import LoadingOverlay from '@dvcode/react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader'
import { useHistory } from 'react-router-dom';
import config from '@config';
import PolygonAnnotationTool from '@components/Annotation/PolygonAnnotationTool';
import PointAnnotationTool from '@components/Annotation/PointAnnotationTool';
import Swal from 'sweetalert2'

function DataLabelingView(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const [isDataLoad, setIsDataLoad] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [polygonAnnotations, setPolygonAnnotations] = useState([]);
  const [newPolygonAnnotation, setNewPolygonAnnotation] = useState([]);
  const [selectedPolygonAnnotation, setSelectedPolygonAnnotation] = useState(null);

  const [pointAnnotations, setPointAnnotations] = useState([]);
  const [selectedPointAnnotation, setSelectedPointAnnotation] = useState(null);

  const [labelingType, setLabelingType] = useState('polygon');
  const [isDrawing, setIsDrawing] = useState(true);
  const [scale, setScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [showMask, setShowMask] = useState(false);

  const annotationToolRef = useRef(null);

  useEffect(() => {
    setIsDataLoad(true)
    dispatch(dataSet(params.data_labeling_id))
    .then((res) => {
      setCurrentItem(res);

      if(res.tci_boundary){
        setPolygonAnnotations([res.tci_boundary])
        setIsDrawing(false)
      }

      if(res.tci){
        setPointAnnotations([...res.tci])
      }

      setIsDataLoad(false)
    })
    .catch(message => {
      history.replace('/sc1/data-labeling')
    });
  }, []);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (polygonAnnotations.length && pointAnnotations.length < 9) {
        showConfirmDialog(location);
        return false;
      }
      return true;
    });

    return unblock;
  }, [history, polygonAnnotations.length > 0 && pointAnnotations.length < 9]);

  const showConfirmDialog = (location) => {
    Swal.fire({
      html: `TCI 라벨링을 완료하지 않아 저장되지 않습니다.<br>정말 페이지를 벗어나시겠습니까?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 이동합니다.',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(location);
      }
    });
  };

  const deleteData = async (location) => {
    try {
      dispatch(updateDataLabel(params.data_labeling_id, {
        tci_boundary: null,
        tci: [],
      })).then(res => {
        setPointAnnotations([]);
        setPolygonAnnotations([]);
        
        toast.success('데이터가 성공적으로 삭제되었습니다.', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        
        setTimeout(() => {
          history.replace(location.pathname);
        }, 100);
      })
    } catch (error) {
      toast.error('데이터 삭제 중 문제가 발생했습니다.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleLabelingTypeChange = (e) => {
    const newType = e.target.value;
    setLabelingType(newType);
    
    if (newType === 'polygon') {
      setSelectedPolygonAnnotation(null);
      setNewPolygonAnnotation([]);
      setIsDrawing(polygonAnnotations.length === 0);
    } else if (newType === 'point') {
      setSelectedPointAnnotation(null);
    }

    resetZoom();
  };

  const startPolygonDrawing = () => {
    setIsDrawing(true);
    setNewPolygonAnnotation([]);
    setSelectedPolygonAnnotation(null);
  };

  const handlePolygonComplete = () => {
    const points = !isDrawing ? polygonAnnotations[polygonAnnotations.length - 1].points : newPolygonAnnotation;

    if (points.length > 1) {
      const rightMostX = Math.max(...points.map(p => p.x));
      const averageY = points.reduce((sum, p) => sum + p.y, 0) / points.length;
      
      const newAnnotationData = { 
        points: points, 
        // color: utils.getRandomColor(), 
        color: 'red', 
        opacity: 0.3,
        label: '-',
        labelPosition: { x: rightMostX + 10, y: averageY }
      };

      dispatch(updateDataLabel(params.data_labeling_id, {
        tci_boundary: newAnnotationData
      }))

      setPolygonAnnotations([newAnnotationData]);
      setNewPolygonAnnotation([]);
      setIsDrawing(false);
    }
  };

  const handlePointAnnotationChange = (newAnnotations) => {
    if (newAnnotations.length > pointAnnotations.length) {
      // 새로운 점이 추가되었을 때
      Swal.fire({
        title: 'TCI 라벨을 선택하세요',
        html: `<select id="swal-select">
          ${Array.from({ length: 3 }, (_, i) => {
            return `<option value="${i}">${i}</option>`;
          }).join('')}
        </select>`,
        focusConfirm: false,
        confirmButtonText: '추가',
        showCancelButton: true,
        cancelButtonText: '취소',
        preConfirm: () => {
          return document.getElementById('swal-select').value
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const label = result.value;
          const newAnnotation = newAnnotations[newAnnotations.length - 1];
          newAnnotation.label = label;
          
          setPointAnnotations([...pointAnnotations, newAnnotation]);

          dispatch(updateDataLabel(params.data_labeling_id, {
            tci: [...pointAnnotations, newAnnotation]
          }))
        }
      });
    } else {
      setPointAnnotations(newAnnotations);
      dispatch(updateDataLabel(params.data_labeling_id, {
        tci: newAnnotations
      }))
    }
  };

  const resetZoom = () => {
    const stage = annotationToolRef.current.getStageRef();
    
    if (!stage) return;

    stage.scale({ x: 1, y: 1 });
    stage.position({ x: 0, y: 0 });
    stage.batchDraw();
    
    setScale(1);
    setStagePosition({ x: 0, y: 0 });
  };

  const handleToggleMask = () => {
    setShowMask(!showMask);
  };

  const handleDeleteAnnotations = () => {
    if (labelingType === 'polygon' && pointAnnotations.length > 0) {
        Swal.fire({
          html: 'TCI 라벨링 정보가 함께 삭제됩니다.<br>계속하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5e72e4',
          confirmButtonText: '확인',
          cancelButtonText: '취소'
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(updateDataLabel(params.data_labeling_id, {
              tci_boundary: null,
              tci: []
            }))
            setPolygonAnnotations([]);
            setPointAnnotations([]);
            setSelectedPolygonAnnotation(null);
            setIsDrawing(true);
          }
        });
    } else {
      Swal.fire({
          text: '선택한 영역을 삭제하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5e72e4',
          confirmButtonText: '확인',
          cancelButtonText: '취소'
      }).then((result) => {
        if (result.isConfirmed) {
          if(labelingType === 'polygon'){
            dispatch(updateDataLabel(params.data_labeling_id, {
              tci_boundary: null
            }))
            setPolygonAnnotations([]);
            setSelectedPolygonAnnotation(null);
            setIsDrawing(true);
          } else {
            const remainingAnnotations = pointAnnotations.filter((_, index) => index !== selectedPointAnnotation);
            setPointAnnotations(remainingAnnotations);
            setSelectedPointAnnotation(null);

            dispatch(updateDataLabel(params.data_labeling_id, {
              tci: remainingAnnotations
            }))            
          }
        }
      });
    }    
  };

  return (
    <>
      <LoadingOverlay
        active={isDataLoad}
        spinner={<ClockLoader size={40} speedMultiplier={2} cssOverride={{marginBottom: '15px'}} color='#fff'/>}
        text='데이터를 불러오는 중입니다.'
      />
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={<Link className="header-link" to={`/sc1/data-labeling${location.search}`}><i className='ni ni-bold-left'/>{props.pageNameText}</Link>}
      />
      {currentItem && <>
      <Progress
        bar
        color="primary"
        value={(polygonAnnotations.length > 0 ? 50 : 0) + Math.round(pointAnnotations.length / 9 * 50)}
        style={{position: 'sticky', top: '71.99px', zIndex: '1000', height: '20px'}}
      >
        {(polygonAnnotations.length > 0 ? 50 : 0) + Math.round(pointAnnotations.length / 9 * 50)}%
      </Progress>
      <Container className="pt-4" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='10%'></col>
                    <col width='15%'></col>
                    <col width='10%'></col>
                    <col width='15%'></col>
                    <col width='10%'></col>
                    <col width='15%'></col>
                    <col width='10%'></col>
                    <col width='15%'></col>
                  </colgroup>
                  <tbody className="thead-light">
                  <tr>
                      <th className="text-center">데이터 구성</th>
                      <td className="text-left">
                        {currentItem.data_category_name}
                      </td>
                      <th className="text-center">환자번호</th>
                      <td className="text-left">{currentItem.patient.patient_id}</td>
                      <th className="text-center">가공</th>
                      <td className="text-left">
                        <Input type="select" defaultValue={labelingType} onChange={handleLabelingTypeChange} disabled={polygonAnnotations.length === 0}>
                            <option value='polygon' selected={labelingType==='polygon'}>TCI Boundary</option>
                            {polygonAnnotations.length && <option value='point' selected={labelingType==='point'}>TCI</option>}
                        </Input>
                      </td>
                      <th className="text-center">진단 이름</th>
                      <td className="text-left">{currentItem.name}</td>
                    </tr>
                  </tbody>
                </Table>
                {labelingType === 'polygon' ? 
                  <PolygonAnnotationTool
                    ref={annotationToolRef}
                    imageFileInfo={currentItem.file}
                    imageUrl={`${config.API_SERVER_HOST}/patients/${currentItem?.patient.patient_id}/datasets/${currentItem.id}/image`}
                    annotations={polygonAnnotations}
                    newAnnotation={newPolygonAnnotation}
                    selectedAnnotation={selectedPolygonAnnotation}
                    isDrawing={isDrawing}
                    scale={scale}
                    stagePosition={stagePosition}
                    showMask={showMask}
                    onStartDrawing={startPolygonDrawing}
                    onCompleteDrawing={handlePolygonComplete}
                    onResetZoom={resetZoom}
                    onDeleteAnnotation={handleDeleteAnnotations}
                    onAnnotationChange={setPolygonAnnotations}
                    onNewAnnotationChange={setNewPolygonAnnotation}
                    onSelectedAnnotationChange={setSelectedPolygonAnnotation}
                    onStagePositionChange={setStagePosition}
                    onScaleChange={setScale}
                    onToggleMask={handleToggleMask}
                  />
                :
                  <PointAnnotationTool
                    ref={annotationToolRef}
                    imageUrl={`${config.API_SERVER_HOST}/patients/${currentItem?.patient.patient_id}/datasets/${currentItem.id}/image`}
                    polygonAnnotation={polygonAnnotations[0]}
                    annotations={pointAnnotations}
                    selectedAnnotation={selectedPointAnnotation}
                    scale={scale}
                    stagePosition={stagePosition}
                    onResetZoom={resetZoom}
                    // onDeleteAnnotation={(index) => {
                    //   const newAnnotations = pointAnnotations.filter((_, i) => i !== index);
                    //   setPointAnnotations(newAnnotations);
                    //   setSelectedPointAnnotation(null);
                    // }}
                    onDeleteAnnotation={handleDeleteAnnotations}
                    onAnnotationChange={handlePointAnnotationChange}
                    onSelectedAnnotationChange={setSelectedPointAnnotation}
                    onStagePositionChange={setStagePosition}
                    onScaleChange={setScale}
                  />
                }
              </CardBody>
            </Card>

            <Card key='result'>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                  <colgroup>
                    <col width='20%'></col>
                    <col width=''></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      <th className="text-center">진단자 최종결과</th>
                      <td className="text-left">
                          {currentItem.result_label_category || '-'}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-center">진단자 의견</th>
                      <td className="text-left">
                        {currentItem.memo || '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container></>}
    </>
  );
}

export default DataLabelingView;