import React, { useEffect, useState } from 'react'

// Chart.js와 필요한 컴포넌트 import
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js"; 
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";

// reactstrap 컴포넌트
import { Card, CardHeader, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";

import { Link, useParams, useLocation } from 'react-router-dom';
import { summary } from '@actions/common';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

Chart.register(CategoryScale);

const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

function Dashboard(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const searchParams = new URLSearchParams(useLocation().search);

  const [currentItem, setCurrentItem] = useState(null);
  
  useEffect(() => {
    dispatch(summary())
    .then((res) => {
      console.log(res);
      setCurrentItem(res);
    })
  }, []);

  return (
    <>
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={props.pageNameText}
      />
      {currentItem && (
        <>
          <div className="header bg-info pb-6">
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col xl="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                              누적 데이터
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {currentItem.totalUpload.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                              누적 데이터 가공
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {currentItem.totalDataLabel.valid_tci_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container className="mt--6" fluid>
            <Row>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">총 데이터 누적 건수</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={{
                          labels: [currentItem.totalUploadByMonth[5]?.date || '', currentItem.totalUploadByMonth[4]?.date || '', currentItem.totalUploadByMonth[3]?.date || '', currentItem.totalUploadByMonth[2]?.date || '', currentItem.totalUploadByMonth[1]?.date || '', currentItem.totalUploadByMonth[0]?.date || ''],
                          datasets: [
                            {
                              label: "Upload",
                              data: [currentItem.totalUploadByMonth[5]?.count, currentItem.totalUploadByMonth[4]?.count, currentItem.totalUploadByMonth[3]?.count, currentItem.totalUploadByMonth[2]?.count, currentItem.totalUploadByMonth[1]?.count, currentItem.totalUploadByMonth[0]?.count],
                              maxBarThickness: 10,
                              backgroundColor: '#5e72e4'
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              beginAtZero: true,
                              grid: {
                                color: colors.gray[200],
                                zeroLineColor: colors.gray[200],
                              },
                              ticks: {
                                callback: function (value) {
                                  if (!(value % 10)) {
                                    return value;
                                  }
                                },
                              },
                            },
                          },
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function (context) {
                                  let label = context.dataset.label || "";
                                  let value = context.parsed.y;
                                  return label + ": " + value;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
               <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">사업소별 누적 데이터 건수</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={{
                          labels: currentItem.divisions,
                          datasets: [
                            {
                              label: "Upload",
                              data: currentItem.divisions.map((division) => {
                                return _.find(currentItem.totalUploadByDivision, r=>r.division === division)?.total_count || 0
                              }),
                              maxBarThickness: 10,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              beginAtZero: true,
                              grid: {
                                color: colors.gray[200],
                                zeroLineColor: colors.gray[200],
                              },
                              ticks: {
                                callback: function (value) {
                                  if (!(value % 10)) {
                                    return value;
                                  }
                                },
                              },
                            },
                          },
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function (context) {
                                  let label = context.dataset.label || "";
                                  let value = context.parsed.y;
                                  return label + ": " + value;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">사업소별 데이터 가공 건수</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={{
                          labels: currentItem.divisions,
                          datasets: [
                            {
                              label: 'Data Labeling',
                              data: currentItem.divisions.map((division) => {
                                return _.find(currentItem.totalDataLabelRateByDivision, r=>r.division === division)?.total || 0
                              }),
                              maxBarThickness: 10,
                              backgroundColor: '#11cdef'
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              beginAtZero: true,
                              grid: {
                                color: colors.gray[200],
                                zeroLineColor: colors.gray[200],
                              },
                              ticks: {
                                callback: function (value) {
                                  if (!(value % 10)) {
                                    return value;
                                  }
                                },
                              },
                            },
                          },
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function (context) {
                                  let label = context.dataset.label || "";
                                  let value = context.parsed.y;
                                  return label + ": " + value;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">사업소별 TCI 비율</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={{
                          labels: currentItem.divisions,
                          datasets: Array.from({length: 19}, (v, i) => {
                            return {
                              label: i,
                              data: currentItem.divisions.map((division) => {
                                const divisionData = _.find(currentItem.totalDataLabelRateByDivision, r=>r.division === division)
                                return divisionData ? _.find(divisionData.categories, r=>r.category === i)?.count || 0 : 0
                              }),
                              maxBarThickness: 10,
                            }
                          })
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            tooltip: {
                              mode: "index",
                              intersect: false,
                            },
                          },
                          scales: {
                            x: {
                              stacked: true,
                            },
                            y: {
                              stacked: true,
                            },
                          },
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default Dashboard;