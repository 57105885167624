import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Stage, Layer, Line, Circle, Image, Text, Group, Rect } from 'react-konva';
import useImage from 'use-image';
import { Button } from 'reactstrap';
import DeleteButton from './DeleteButton';
import { toast } from 'react-toastify';

const PolygonAnnotationTool = forwardRef(({
  imageUrl,
  annotations,
  newAnnotation,
  selectedAnnotation,
  isDrawing,
  scale,
  stagePosition,
  showMask,
  onStartDrawing,
  onCompleteDrawing,
  onResetZoom,
  onDeleteAnnotation,
  onAnnotationChange,
  onNewAnnotationChange,
  onSelectedAnnotationChange,
  onScaleChange,
  onStagePositionChange,
  onToggleMask,
}, ref) => {
  const [stageSize] = useState({ width: 1422, height: 800 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageOffset, setImageOffset] = useState({ x: 0, y: 0 });
  const [image, imageStatus] = useImage(imageUrl || "");
  const layerRef = useRef();
  const stageRef = useRef(null);
  const isShiftPressed = useRef(false);
  const [isCircleDragging, setIsCircleDragging] = useState(false);
  const scaleBy = 1.1;

  useImperativeHandle(ref, () => ({
    getStageRef: () => stageRef.current,
  }));

  const calculateImageSize = (img) => {
    if (!img) return { width: 0, height: 0 };
    const scale = Math.min(stageSize.width / img.width, stageSize.height / img.height);
    return {
      width: img.width * scale,
      height: img.height * scale
    };
  };

  useEffect(() => {
    if (image) {
      const { width, height } = calculateImageSize(image);
      setImageSize({ width, height });
      setImageOffset({
        x: (stageSize.width - width) / 2,
        y: (stageSize.height - height) / 2
      });
    }
  }, [image]);

  const handleKeyDown = (e) => {
    if (e.key === 'Shift') {
      isShiftPressed.current = true;
    }
    if (e.key === 'Delete') {
      onDeleteAnnotation();
    }
    if (e.key === 'Backspace') {
      if(newAnnotation.length > 0) {
        handleUndo()
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Shift') {
      isShiftPressed.current = false;
    }
    if (e.key === 'Enter') {
      onCompleteDrawing();
    }
  };

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    const relativePos = transform.point(pos);
    return {
      x: relativePos.x - imageOffset.x,
      y: relativePos.y - imageOffset.y
    };
  };

  const handleLayerClick = (e) => {
    if (!isDrawing) {
      handleBackgroundClick(e);
      return;
    }

    const pos = getRelativePointerPosition(e.target.getStage());

    if (newAnnotation.length > 0) {
      const firstPoint = newAnnotation[0];
      const dx = pos.x - firstPoint.x;
      const dy = pos.y - firstPoint.y;

      if (Math.sqrt(dx * dx + dy * dy) < 10) {
        onCompleteDrawing();
        return;
      }

      onNewAnnotationChange([...newAnnotation, { x: pos.x, y: pos.y }]);
    } else {
      onNewAnnotationChange([{ x: pos.x, y: pos.y }]);
    }
  };

  const handleBackgroundClick = (e) => {
    const clickedOnAnnotation = e.target.name() === 'Line';
    if (!clickedOnAnnotation && selectedAnnotation !== null) {
      onSelectedAnnotationChange(null);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const pos = getRelativePointerPosition(e.target.getStage());

    if (isShiftPressed.current) {
      const newPoint = { x: pos.x, y: pos.y };
      if (newAnnotation.length === 0) {
        onNewAnnotationChange([newPoint]);
      } else {
        const lastPoint = newAnnotation[newAnnotation.length - 1];
        const dx = pos.x - lastPoint.x;
        const dy = pos.y - lastPoint.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance > 10) {
          onNewAnnotationChange([...newAnnotation, newPoint]);
        }
      }
    }
  };

  const handleDragMove = (id, pointIndex, newPos) => {
    const updatedAnnotations = annotations.map((annotation, index) => {
      if (id === index) {
        const newPolygon = annotation.points.slice();
        newPolygon[pointIndex] = newPos;
        return { ...annotation, points: newPolygon };
      }
      return annotation;
    });
    onAnnotationChange(updatedAnnotations);
  };

  const handleDragEnd = (id, pointIndex, newPos) => {
    const updatedAnnotations = annotations.map((annotation, index) => {
      if (id === index) {
        const newPolygon = annotation.points.slice();
        newPolygon.splice(pointIndex, 1, newPos);
        const rightMostX = Math.max(...newPolygon.map(p => p.x));
        const averageY = newPolygon.reduce((sum, p) => sum + p.y, 0) / newPolygon.length;
        return { 
          ...annotation, 
          points: newPolygon,
          labelPosition: { x: rightMostX + 10, y: averageY }
        };
      }
      return annotation;
    });
    onAnnotationChange(updatedAnnotations);
    onCompleteDrawing();
    setIsCircleDragging(false);
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();
    if (isShiftPressed.current) return;

    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < 0.5) return;
    if (newScale > 3) return;

    onScaleChange(newScale);

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  };

  const handleStageMouseDown = (e) => {
    if(isDrawing && e.target instanceof Konva.Stage){
      toast.error('이미지 영역을 클릭해주세요.', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
  };

  const handleUndo = () => {
    if (newAnnotation.length > 0) {
      const updatedAnnotation = [...newAnnotation];
      updatedAnnotation.pop();
      onNewAnnotationChange(updatedAnnotation);
    }
  };

  const ControlPanel = ({ scale, onResetZoom, showMask, onToggleMask, onUndo, canUndo }) => (
    <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
      <div style={{ backgroundColor: '#ececec', padding: '10px', borderRadius: '5px', opacity: 1, textAlign: 'center' }}>
        {(scale !== 1 || stagePosition.x !== 0 || stagePosition.y !== 0) && 
          <Button color="primary" onClick={onResetZoom} size="sm">
            화면 원래대로
          </Button>}
        {canUndo && 
          <Button color="warning" onClick={onUndo} size="sm" style={{marginRight: '5px'}}>
            실행 취소
          </Button>}
        {annotations.length > 0 && <Button color={showMask ? "success" : "secondary"} onClick={onToggleMask} size="sm">
          {showMask ? "비트맵 숨기기" : "비트맵 표시"}
        </Button>}
      </div>
    </div>
  );

  const Message = ({
    children,
  }) => (
    <div
      style={{
        position: 'absolute',
        left: `50%`,
        top: `10px`,
        transform: `translate(-50%, 0)`,
        backgroundColor: 'rgba(94, 114, 228, 0.7)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        zIndex: 100,
        pointerEvents: 'none',
        textAlign: 'center',
      }}
    >
      {children}
    </div>
  );

  return (
    <div
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      tabIndex="0"
      style={{ outline: 'none', position: 'relative', border: '1px solid #ececec'}}
      onContextMenu={(e) => e.preventDefault()} // 전체 컴포넌트에 대한 우클릭 방지
    >
      {
        imageStatus === 'failed' && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <i className="fas fa-exclamation-triangle" style={{ fontSize: '24px', color: 'red', marginRight: '5px' }}></i>
          이미지를 불러오는데 실패했습니다.
        </div>
      }

      {image && ((scale !== 1 || stagePosition.x !== 0 || stagePosition.y !== 0) || annotations.length > 0 || newAnnotation.length > 0) && <ControlPanel
        scale={scale}
        isDrawing={isDrawing}
        newAnnotation={newAnnotation}
        selectedAnnotation={selectedAnnotation}
        onStartDrawing={onStartDrawing}
        onCompleteDrawing={onCompleteDrawing}
        onResetZoom={onResetZoom}
        onDeleteAnnotation={onDeleteAnnotation}
        showMask={showMask}
        onToggleMask={onToggleMask}
        onUndo={handleUndo}
        canUndo={newAnnotation.length > 0}
      />}
      {image && isDrawing && newAnnotation.length === 0 &&  <Message>TCI 라벨링전 주위에 다각형을 그려주세요. 시작하려면 이미지을 클릭하세요.</Message>}
      {image && isDrawing && newAnnotation.length !== 0 &&  <Message>클릭하여 점을 찍고, 선을 연결하세요.<br/>영역 설정을 완료하려면 시작점을 클릭하거나 엔터키를 눌러주세요.</Message>}
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        onWheel={handleWheel}
        ref={stageRef}
        onMouseDown={handleStageMouseDown}
        onDragMove={(e) => {
          if (e.target instanceof Konva.Circle) return;
          onStagePositionChange({ x: e.target.x(), y: e.target.y() })
        }}
        draggable={!isCircleDragging}
      >
        {image && <Layer ref={layerRef} onClick={handleLayerClick} onMouseMove={handleMouseMove}>
           <Image
              image={image}
              width={imageSize.width}
              height={imageSize.height}
              x={imageOffset.x}
              y={imageOffset.y}
              opacity={showMask ? 0 : 1}
          />
          <Group x={imageOffset.x} y={imageOffset.y}>
            {annotations.map((annotation, index) => (
              <React.Fragment key={index}>
                <Line
                  points={annotation.points.flatMap(p => [p.x, p.y])}
                  stroke={selectedAnnotation === index ? "blue" : "red"}
                  strokeWidth={selectedAnnotation === index ? 3 : 2}
                  fill={selectedAnnotation === index ? annotation.color : 'transparent'}
                  opacity={selectedAnnotation === index ? annotation.opacity : 0.8}
                  closed
                  onClick={() => onSelectedAnnotationChange(index)}
                />
                {selectedAnnotation !== null && <DeleteButton
                  x={annotation.labelPosition.x}
                  y={annotation.labelPosition.y}
                  onDelete={() => onDeleteAnnotation(index)}
                  scale={scale}
                />}
                {selectedAnnotation === index && annotation.points.map((point, pointIndex) => (
                  <Circle
                    key={`${index}-${pointIndex}`}
                    x={point.x}
                    y={point.y}
                    radius={5 / scale}
                    fill="red"
                    draggable
                    onDragStart={() => setIsCircleDragging(true)}
                    onDragMove={(e) =>
                      handleDragMove(index, pointIndex, {
                        x: e.target.x(),
                        y: e.target.y(),
                      })
                    }
                    onDragEnd={(e) =>
                      handleDragEnd(index, pointIndex, {
                        x: e.target.x(),
                        y: e.target.y(),
                      })
                    }
                  />
                ))}
              </React.Fragment>
            ))}
            {newAnnotation.length > 0 && (
              <React.Fragment>
                <Line
                  points={newAnnotation.flatMap(p => [p.x, p.y])}
                  stroke="blue"
                  fill="rgba(0, 0, 255, 0.2)"
                  closed={true}
                />
                {newAnnotation.map((point, index) => (
                  <Circle
                    key={index}
                    x={point.x}
                    y={point.y}
                    radius={3/scale}
                    fill="#fff"
                  />
                ))}
                {newAnnotation.length > 1 && (
                  <Line
                    points={[
                      newAnnotation[0].x, newAnnotation[0].y,
                      newAnnotation[newAnnotation.length - 1].x, newAnnotation[newAnnotation.length - 1].y,
                    ]}
                    stroke="#fff"
                    dash={[5, 5]}
                  />
                )}
              </React.Fragment>
            )}
          </Group>
        </Layer>}
        <Layer visible={showMask}>
          <Rect
            x={0}
            y={0}
            width={stageSize.width}
            height={stageSize.height}
            fill="black"
          />
          <Group x={imageOffset.x} y={imageOffset.y}>
            {annotations.map((annotation, index) => (
              <Line
                key={index}
                points={annotation.points.flatMap(p => [p.x, p.y])}
                closed
                fill="#ffffff"
                stroke="transparent"
              />
            ))}
          </Group>
        </Layer>
      </Stage>
    </div>
  );
});

export default PolygonAnnotationTool;