import React, {useEffect, useState} from 'react'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  FormGroup,
  Form,
  Button,
  Input,
  Row,
  Col
} from "reactstrap";
import ReactEcharts from "echarts-for-react"; 
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Link, useParams, useLocation } from 'react-router-dom';
import { alresult } from '@actions/aianaysis';
import { createDataLabel } from '@actions/aianaysis';
import { useDispatch, useSelector } from 'react-redux';
import testCategory from '@data/testCategory';
import dataCategory from '@data/dataCategory';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '@dvcode/react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader'
import config from '@config';
import utils from 'utils';

function DataAnalyticsView(props) {
  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const [isDataLoad, setIsDataLoad] = useState(false);
  const [dataLabel, setDataLabel] = useState({});
  const [dataLabelResult, setDataLabelResult] = useState({});
  const [currentItem, setCurrentItem] = useState(null);
  
  useEffect(() => {
    setIsDataLoad(true)
    dispatch(alresult(params.ai_analysis_data_id))
    .then((res) => {
      setIsDataLoad(false)
      setCurrentItem(res);
    })
    .catch(message => {
      setIsDataLoad(false)
      history.replace('/sc0/ai-analysis-data')
    });
  }, []);

  return (
    <>
      <LoadingOverlay
        active={isDataLoad}
        spinner={<ClockLoader size={40} speedMultiplier={2} cssOverride={{marginBottom: '15px'}} color='#fff'/>}
        text='데이터를 불러오는 중입니다.'
      />
      <AdminNavbar
        toggleSidenav={props.toggleSidenav}
        sidenavOpen={props.sidenavOpen}
        pageNameText={<Link className="header-link" to={`/sc0/ai-analysis-data${location.search}`}><i className='ni ni-bold-left' /> {props.pageNameText}</Link>}
      />
      {currentItem && <Container className="pt-5" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardBody>
                <Table className="align-items-center table-flush" responsive striped>
                  <colgroup>
                    <col width='15%'></col>
                    <col width='35%'></col>
                    <col width='15%'></col>
                    <col width='35%'></col>
                  </colgroup>
                  <tbody className="thead-light">
                    <tr>
                      <th className="text-center">진단 장비</th>
                      <td className="text-left">{testCategory[currentItem.test_category].name}</td>
                      <th className="text-center">진단 이름</th>
                      <td className="text-left">{currentItem.dataset.name}</td>
                    </tr>
                    <tr>
                      <th className="text-center">데이터 항목</th>
                      <td className="text-left">
                        {dataCategory.find(r=>r.code === currentItem.data_category)?.name}
                      </td>
                      <th className="text-center">인공지능 결과</th>
                      <td className="text-left">
                        {currentItem.label_category}
                      </td>
                    </tr>
                    <tr>
                      <th className="text-center">MIN</th>
                      <td className="text-left">{currentItem.min}</td>
                      <th className="text-center">MAX</th>
                      <td className="text-left">{currentItem.max}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            {currentItem.complete ? <>
              <Card>
                <CardBody>
                  {/* <img src={`${config.API_SERVER_HOST}/output/${currentItem.test_category}-images/${currentItem.dataset?.raw_data?.id}_Signal_${currentItem.data_category}.png`} style={{width: '100%', height: '100%'}}/> */}
                  {currentItem?.data?.length > 0 ? <ReactEcharts option={{
                    title: {
                      left: 'center',
                      text: currentItem.data_category === 'ECG' ? 'Tachogram' : 'Signal Graph',
                      subtext: currentItem.data_category === 'ECG' ?  'NN Interval' : '',
                    },
                    xAxis: {
                      type: 'category',
                      data: Array(currentItem.data.length).fill(0).map((v, i)=> utils.toHHMMSS(currentItem.data_category === 'SpO2' ? i/10 : i))
                    },
                    yAxis: {
                      type: 'value',
                      nameLocation: 'middle',
                      min: currentItem.min,
                    },
                    dataZoom: [
                      {
                        show: true,
                        start: 0,
                        end: 10
                      },
                      {
                        type: 'inside',
                        start: 0,
                        end: 10
                      }
                    ],
                    tooltip: {
                      trigger: 'axis',
                      position: function (pt) {
                        return [pt[0], '10%'];
                      }
                    },
                    series: [
                      {
                        data: currentItem.data,
                        type: 'line',
                      },
                      {
                        data: Array(currentItem.data.length).fill().map((v, i)=> currentItem.max),
                        type: 'line',
                      },
                      {
                        data: Array(currentItem.data.length).fill().map((v, i)=> currentItem.min),
                        type: 'line',
                      },
                    ]
                  }} /> : <img src={`${config.API_SERVER_HOST}/output/${currentItem.test_category}-images/${currentItem.dataset?.raw_data?.id}_Signal_${currentItem.data_category}.png`} style={{width: '100%', height: '100%'}}/>}
                  <img src={`${config.API_SERVER_HOST}/output/${currentItem.test_category}-images/${currentItem.dataset?.raw_data?.id}_${currentItem.data_category}.png`} style={{width: '100%', height: '100%'}}/>
                </CardBody>
              </Card>
            </> : null}

            <Card>
              <CardBody>
                <Table className="align-items-center table-flush ai-analysis-view-table" responsive striped>
                    <colgroup>
                      <col width='20%'></col>
                      <col width=''></col>
                    </colgroup>
                    <tbody className="thead-light">
                      <tr>
                        <th className="text-center">진단자 최종결과</th>
                        <td className="text-left">
                          {currentItem?.dataset?.result_label_category || 'None'}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-center">진단자 의견</th>
                        <td className="text-left">
                          {currentItem.dataset.memo}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </>
  );
}

export default DataAnalyticsView;
