import React from 'react';
import { Line, Group } from 'react-konva';

const calculatePolygonBounds = (points) => {
  const xs = points.map(p => p.x);
  const ys = points.map(p => p.y);
  return {
    minX: Math.min(...xs),
    maxX: Math.max(...xs),
    minY: Math.min(...ys),
    maxY: Math.max(...ys),
  };
};

const DrawGrid = ({ points }) => {
  const bounds = calculatePolygonBounds(points);
  const width = bounds.maxX - bounds.minX;
  const height = bounds.maxY - bounds.minY;

  const horizontalLines = [1/3, 2/3].map(ratio => ({
    points: [
      bounds.minX, bounds.minY + height * ratio,
      bounds.maxX, bounds.minY + height * ratio
    ]
  }));

  const verticalLines = [1/3, 2/3].map(ratio => ({
    points: [
      bounds.minX + width * ratio, bounds.minY,
      bounds.minX + width * ratio, bounds.maxY
    ]
  }));

  return (
    <Group
      clipFunc={(ctx) => {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        points.slice(1).forEach(point => {
          ctx.lineTo(point.x, point.y);
        });
        ctx.closePath();
      }}
    >
      {[...horizontalLines, ...verticalLines].map((line, index) => (
        <Line
          key={index}
          points={line.points}
          stroke="#FFFFFF"
          strokeWidth={2}
          dash={[6, 4]}
          opacity={0.8}
        />
      ))}
    </Group>
  );
};

export default DrawGrid;