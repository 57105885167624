import CommonService from '@services/common.service';

export const summary = () => (dispatch) => {
  return CommonService.summary()
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};