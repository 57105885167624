import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Stage, Layer, Circle, Image, Label, Tag, Text, Group, Rect, Line } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import { Button, Input, Table, Card, CardBody } from 'reactstrap';
import DrawGrid from './DrawGrid';
import DeleteButton from './DeleteButton';
import PointLabel from './PointLabel';
import Message from './Message';

const PointAnnotationTool = forwardRef(({
  viewOnly = false,
  imageUrl,
  polygonAnnotation,
  annotations,
  selectedAnnotation,
  scale,
  stagePosition,
  onResetZoom,
  onDeleteAnnotation,
  onAnnotationChange,
  onSelectedAnnotationChange,
  onScaleChange,
  onStagePositionChange
}, ref) => {
  const [stageSize] = useState({ width: 1422, height: 800 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageOffset, setImageOffset] = useState({ x: 0, y: 0 });
  const [image, imageStatus] = useImage(imageUrl || "");
  const layerRef = useRef();
  const stageRef = useRef(null);
  const [isPointDragging, setIsPointDragging] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const scaleBy = 1.1;

  useImperativeHandle(ref, () => ({
    getStageRef: () => stageRef.current,
  }));

  const calculateImageSize = (img) => {
    if (!img) return { width: 0, height: 0 };
    const scale = Math.min(stageSize.width / img.width, stageSize.height / img.height);
    return {
      width: img.width * scale,
      height: img.height * scale
    };
  };

  useEffect(() => {
    if (image) {
      const { width, height } = calculateImageSize(image);
      setImageSize({ width, height });
      setImageOffset({
        x: (stageSize.width - width) / 2,
        y: (stageSize.height - height) / 2
      });
    }

  }, [image]);

  const handleKeyDown = (e) => {
    if (e.key === 'Delete') {
      onDeleteAnnotation(selectedAnnotation);
    }
  };

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    const relativePos = transform.point(pos);
    return {
      x: relativePos.x - imageOffset.x,
      y: relativePos.y - imageOffset.y
    };
  };

  const handleLayerClick = (e) => {
    if(viewOnly) return;
    
    if (e.target instanceof Konva.Rect && e.target.parent instanceof Konva.Group) {
      console.log("DeleteButton area clicked");
      return;
    }

    if (annotations.length < 9) {
      const pos = getRelativePointerPosition(e.target.getStage());

      const newAnnotation = {
        points: [pos],
        label: `Point ${annotations.length + 1}`,
        labelPosition: { x: pos.x + 5 / scale, y: pos.y + 5 / scale },
      };
      
      const updatedAnnotations = [...annotations, newAnnotation];
      onAnnotationChange(updatedAnnotations);
    } else if (!(e.target instanceof Konva.Circle)) {
      onSelectedAnnotationChange(null);
    }
  };

  const handleCircleClick = (e, index) => {
    if (viewOnly) return;

    e.cancelBubble = true;
    onSelectedAnnotationChange(index);
  };

  const handleDragStart = (e) => {
    setIsPointDragging(true);
    e.target.moveToTop();
  };

  const handleDragMove = (id, pointIndex, newPos) => {
    setCursorPosition(newPos);  // 마우스 위치 업데이트
    const updatedAnnotations = annotations.map((annotation, index) => {
      if (id === index) {
        const newPoints = [...annotation.points];
        newPoints[pointIndex] = newPos;
        return { 
          ...annotation, 
          points: newPoints,
          labelPosition: { x: newPos.x + 10 / scale, y: newPos.y + 10 / scale }
        };
      }
      return annotation;
    });
    onAnnotationChange(updatedAnnotations);
  };

  const handleDragEnd = (id, pointIndex, newPos) => {
    handleDragMove(id, pointIndex, newPos);
    setIsPointDragging(false);
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < 0.5) return;
    if (newScale > 3) return;

    onScaleChange(newScale);

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    
    stage.position(newPos);
    stage.batchDraw();

    onStagePositionChange(newPos);
  };

  const handleMouseMove = (e) => {
    if (!isPointDragging) {
      const stage = e.target.getStage();
      const pos = getRelativePointerPosition(stage);
      setCursorPosition(pos);
    }
  };

  const ControlPanel = ({ scale, onResetZoom }) => (
    <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
      <div style={{ backgroundColor: '#ececec', padding: '10px', borderRadius: '5px', opacity: 1, textAlign: 'center' }}>
        {(scale !== 1 || stagePosition.x !== 0 || stagePosition.y !== 0) && 
         <Button color="primary" onClick={onResetZoom} size="sm">
              화면 원래대로
         </Button>}
      </div>
    </div>
  );

  const LabelPanel = ({viewOnly=false, annotations, selectedAnnotation, onLabelChange }) => (
    <Card style={{ position: 'absolute', top: '10px', right: `${10}px`, zIndex: 1, width: '200px', maxHeight: '70vh', overflowY: 'auto' }}>
      <CardBody className="p-0">
        <Table bordered size="sm" className="mb-0">
          <thead>
            <tr>
              <th className="text-center" style={{ verticalAlign: 'middle', backgroundColor:'#ececec', borderBottom: 'double' }}>TCI</th>
              <th className="text-center" style={{ verticalAlign: 'middle', backgroundColor:'#ececec', borderBottom: 'double' }}>라벨</th>
            </tr>
          </thead>
          <tbody>
            {annotations.map((annotation, index) => (
              <tr key={index}>
                <td className="text-center" style={{ verticalAlign: 'middle', padding: '0.5rem', backgroundColor: selectedAnnotation === index ? '#5e72e4' : 'white', color: selectedAnnotation === index ? 'white' : 'black' }}>{index + 1}</td>
                <td className="text-center" style={{ verticalAlign: 'middle', padding: '0.5rem' }}>
                  {viewOnly ? annotation.label : <Input
                    type="select"
                    bsSize="sm"
                    defaultValue={annotation.label}
                    onChange={(e) => onLabelChange(index, e.target.value)}
                    style={{ margin: 0 }}
                  >
                    {Array.from({ length: 3 }, (_, i) => (
                      <option key={i} value={i}>{i}</option>
                    ))}
                  </Input>}
                </td>
              </tr>
            ))}
          </tbody>
         {annotations.length === 9 && <tfoot>
            <tr>
              <td className="text-center" style={{ verticalAlign: 'middle', padding: '0.5rem' }}>
                결과
              </td>
              <td className="text-center" style={{ verticalAlign: 'middle', padding: '0.5rem' }}>
                {annotations.reduce((acc, cur) => acc + parseInt(cur.label), 0)}
              </td>
            </tr>
          </tfoot>}
        </Table>
      </CardBody>
    </Card>
  );
  
  const handleLabelChange = (index, newLabel) => {
    const updatedAnnotations = annotations.map((annotation, i) => 
      i === index ? { ...annotation, label: newLabel } : annotation
    );
    onAnnotationChange(updatedAnnotations);
    if(selectedAnnotation !== null) {
      onSelectedAnnotationChange(null);
    }
  };

  const showGuideLines = annotations.length < 9 || isPointDragging;

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex="0"
      style={{ outline: 'none', position: 'relative', border: '1px solid #ececec'}}
      onContextMenu={(e) => e.preventDefault()} // 전체 컴포넌트에 대한 우클릭 방지
    >
      {
        imageStatus === 'failed' && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <i className="fas fa-exclamation-triangle" style={{ fontSize: '24px', color: 'red', marginRight: '5px' }}></i>
          이미지를 불러오는데 실패했습니다.
        </div>
      }

      {image && (scale !== 1 || stagePosition.x !== 0 || stagePosition.y !== 0) && <ControlPanel
        scale={scale}
        onResetZoom={onResetZoom}
      />}

      {image && annotations.length > 0 && <LabelPanel
        viewOnly={viewOnly}
        selectedAnnotation={selectedAnnotation}
        annotations={annotations}
        onLabelChange={handleLabelChange}
      />}

      {image && !viewOnly && annotations.length < 9 && <Message>흰색 영역 안에 TCI를 추가하고 해당 라벨을 선택해 주세요.<br/>({9 - annotations.length}개 남음)</Message>}

      <Stage
        width={stageSize.width}
        height={stageSize.height}
        onWheel={handleWheel}
        ref={stageRef}
        draggable={!isPointDragging}
        onDragEnd={(e) => {
          if (!isPointDragging) {
            onStagePositionChange({ x: e.target.x(), y: e.target.y() });
          }
        }}
        onMouseMove={handleMouseMove}
      >
        {image && <Layer ref={layerRef} onClick={handleLayerClick}>
          <Image
            image={image}
            width={imageSize.width}
            height={imageSize.height}
            x={imageOffset.x}
            y={imageOffset.y}
          />

          <Group x={imageOffset.x} y={imageOffset.y}>
            {/* 가이드 라인 */}

            {polygonAnnotation && <DrawGrid points={polygonAnnotation.points} />}

            {polygonAnnotation && <Line
                points={[
                  ...polygonAnnotation.points.flatMap(p => [p.x, p.y]),
                  polygonAnnotation.points[0].x,
                  polygonAnnotation.points[0].y
                ]}
                stroke="white"
                strokeWidth={2/scale}
                dash={[5/scale, 5/scale]}
            />}

            {annotations.map((annotation, index) => (
              <React.Fragment key={index}>
                <Group 
                  x={annotation.points[0].x} 
                  y={annotation.points[0].y}
                  draggable={selectedAnnotation === index}
                  onDragStart={selectedAnnotation === index ? handleDragStart : null}
                  onDragMove={selectedAnnotation === index ? (e) => {
                    const stage = e.target.getStage();
                    const pos = getRelativePointerPosition(stage);
                    handleDragMove(index, 0, pos);
                  } : null}
                  onDragEnd={selectedAnnotation === index ? (e) => {
                    const stage = e.target.getStage();
                    const pos = getRelativePointerPosition(stage);
                    handleDragEnd(index, 0, pos);
                  } : null}
                  onClick={(e) => handleCircleClick(e, index)}
                  >
                  <Circle
                    radius={7 / scale}
                    fill={selectedAnnotation === index ? "#5e72e4" : "red"}
                    stroke={selectedAnnotation === index ? "#5e72e4" : "red"}
                    strokeWidth={2 / scale}                    
                    opacity={selectedAnnotation === index ? 1 : 0.5}
                  />
                  <Text
                    x={-4 / scale}
                    y={-6 / scale}
                    text={index + 1}
                    fontSize={15 / scale}
                    fill="white"
                  />
                </Group>
                {selectedAnnotation === index && (
                  <DeleteButton
                    x={annotation.labelPosition.x}
                    y={annotation.labelPosition.y}
                    onDelete={() => onDeleteAnnotation(index)}
                    scale={scale}
                  />
                )} 
                {(selectedAnnotation === null || viewOnly) && (
                  <PointLabel
                    x={annotation.labelPosition.x}
                    y={annotation.labelPosition.y}
                    text={annotation.label}
                    scale={scale}
                  />
                )}
              </React.Fragment>
            ))}
          </Group>
          {!viewOnly && showGuideLines && <>
            <Line
                points={[
                  cursorPosition.x + imageOffset.x,
                  imageOffset.y,
                  cursorPosition.x + imageOffset.x,
                  imageOffset.y + imageSize.height
                ]}
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth={2 / scale}
              />
              <Line
                points={[
                  imageOffset.x,
                  cursorPosition.y + imageOffset.y,
                  imageOffset.x + imageSize.width,
                  cursorPosition.y + imageOffset.y
                ]}
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth={2 / scale}
              />
          </>}
        </Layer>}
      </Stage>
    </div>
  );
});

export default PointAnnotationTool;